/*####################*/
/*General Colors and Variables*/
/*####################*/
@black: #000;
@grey: #575759;
@white: #fff;
@blue:#54758c;
@fair: #fafbf1;

@fullwidth:582px;

@colwidth:27px;
@gutterwidth:10px;
@boxpaddingbottom:70px;
@boxpaddingtop:56px;
@boxmargin:116px;

@basefontSize:1em;
@largeFont: @basefontSize * 3;
@mediumFont: @basefontSize * 1.5;
@baselineHeight: 1.5em;


/*####################*/
/*define the grid*/
/*####################*/
@wide1:@colwidth + @gutterwidth;
@wide2:(@colwidth + @gutterwidth) * 2;
@wide3:(@colwidth + @gutterwidth) * 3;
@wide4:(@colwidth + @gutterwidth) * 4;
@wide5:(@colwidth + @gutterwidth) * 5;
@wide6:(@colwidth + @gutterwidth) * 6;
@wide7:(@colwidth + @gutterwidth) * 7;
@wide8:(@colwidth + @gutterwidth) * 8;
@wide9:(@colwidth + @gutterwidth) * 9;
@wide10:(@colwidth + @gutterwidth) * 10;
@wide11:(@colwidth + @gutterwidth) * 11;
@wide12:(@colwidth + @gutterwidth) * 12;
@wide13:(@colwidth + @gutterwidth) * 13;
@wide14:(@colwidth + @gutterwidth) * 14;


/*####################*/
/*Mixins*/
/*####################*/
.relative{
	position:relative;
}
.resetList{
	margin:0;
	padding:0;
	list-style:none;
}
.mainwidth{
	width:@fullwidth;
	padding:0 9px 0 9px;
	margin:0 auto;
}
.border-radius-bottom{
	-webkit-border-bottom-right-radius: 12px;
	-webkit-border-bottom-left-radius: 12px;
	-moz-border-radius-bottomright: 12px;
	-moz-border-radius-bottomleft: 12px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
}

.box-shadow (@x: 0, @y: 0, @blur: 1px, @color: #999) {
  box-shadow: @arguments;
  -moz-box-shadow: @arguments;
  -webkit-box-shadow: @arguments;
}

/*####################*/
/*Scroll stuff*/
/*####################*/
.absolute{
	position:absolute;
	z-index:2;
}
.mask{
	position:relative;
	z-index:20;
}
.ontop{
	position:relative;
	z-index:10;
	background:@white;
	height:450px;
}
#kreis-1, #kreis-2, #kreis-3, #kreis-4, #kreis-5, #kreis-6 {
	display:none;
}

img{
	width:100%;
}

/*####################*/
/*Show and Hidem and Helper*/
/*####################*/
.disable-mobile{
	display:block;
}
.visible-tablet{
	display:block;
}
.visible-mobile, .visible-desktop{
	display:none!important;
}
.clear{
	clear:both;
}

/*####################*/
/*General Formats*/
/*####################*/
body{
	font-family: 'Noto Sans', sans-serif;
	background:@white;
	font-size:@basefontSize;
	line-height:@baselineHeight;
}
h1{
	font-size:@largeFont;
	margin:0;
	line-height:@baselineHeight;
}
h2{
	font-size:@mediumFont;
	margin:0;
	font-style:italic;
	line-height:@baselineHeight;
}
h3{
	font-size:@basefontSize * 1.2;
	line-height:@baselineHeight;
	margin:0;
	font-style:italic;
}
ul, li{
	.resetList;
	list-style:disc;
}
ul{
	margin:0 0 20px 15px;
}
ul li{
	line-height:@baselineHeight;
}

dl{
	.resetList;
}

dt{
	font-size:@basefontSize * 1.2;
	line-height:@baselineHeight;
	margin:20px 0 0 0;
	font-style:italic;
	font-weight:700;	
}
dd{
	list-style:disc;
	margin:0;
	padding:0 0 0 10px;
	line-height:@baselineHeight;
	background: url(../../img/list.png) no-repeat 0px 10px;
}

a{
	text-decoration:none;
}
a:hover{
	text-decoration:underline;
}
/*####################*/
/* General Boxes*/
/*####################*/
/*2 cols*/
div.cols div{
	float:left;
}
div.cols div.last{
	float:right;
	margin: 0 0 0 -@gutterwidth;
}

.row-2{
	width:@wide2;
}
.row-3{
	width:@wide3;
}
.row-4{
	width:@wide4;
}
.row-5{
	width:@wide5;
}
.row-6{
	width:@wide6;
}
.row-7{
	width:@wide7;
}
.row-8{
	width:@wide8;
}
.row-9{
	width:@wide9;
}
.row-10{
	width:@wide10;
}
.row-11{
	width:@wide11;
}
.row-12{
	width:@wide12;
}
.row-13{
	width:@wide13;
}
.row-14{
	width:@wide14;
}
/*####################*/
/* General Boxes*/
/*####################*/

div.mainwidth{
	.mainwidth;
}
header{
	position:fixed;
	background: url(../../img/headerback.png) repeat-x top left;
	height:116px;
	width:100%;
	z-index:2000;
	top:0;
}
section{
	min-height:200px;
	position:relative;
	z-index:20;
}

section#home{
	background:@white;
	margin:@boxmargin 0 5px 0;
	padding:0;
	color:@blue;
	.box-shadow(0px, 5px);
	z-index:10;
}
section#home div.fair{
	display:none;
}
section#space{
	background:@fair;
	height:282px;
	margin:0;
	padding:0;
	z-index:1;
}
section#angebot, section#netzwerk{
	background:@blue;
	color:@white;
	padding:@boxpaddingtop 0 @boxpaddingbottom 0;
	margin:@boxmargin 0 @boxmargin 0;
}
section#angebot{
	margin-top:0;
	background:@blue url(../../img/border.png) repeat-x top left;
}
section#profil, section#referenzen, section#kontakt{
	background:@white;
	color:@blue;
	padding:@boxpaddingtop 0 @boxpaddingbottom 0;
	margin:@boxmargin 0 @boxmargin 0;
}
section#referenzen{
	padding:0 0 @boxpaddingbottom 0;
	word-wrap: break-word;
	font-size:11px;
}
section#impressum{
	background:@grey;
	color:@white;
	padding:@boxpaddingtop 0 @boxpaddingbottom 0;
	margin:@boxmargin 0 0 0;
}

section#home a, section#profil a, section#referenzen a, section#kontakt a{
	color:@blue;
}
section#impressum a, section#angebot a, section#netzwerk a{
	color:@white;
}
/*####################*/
/*Nav*/
/*####################*/
nav{

}
nav ul{
	.resetList;
	background:@grey;
	.border-radius-bottom;
	width:305px;
	height:80px;
	padding-top:20px;
	float:left;
}
nav ul li{
	.resetList;
	display:inline;
}
nav ul li a:link, nav ul li a:visited{
	color:@white;
	padding:0 0 0 20px;
	text-decoration:none;
	text-transform:uppercase;
	font-size:13px;
}
nav ul li a.current, nav ul li a:hover, nav ul li a:active{
	background: url(../../img/navact.png) no-repeat 5px 4px;
}

/*####################*/
/*Logo*/
/*####################*/
div#logo{
	float:right;
}
div#logo a.logo{
	display:block;
	height:79px;
	width:250px;
	background:url(../../img/logo2.png) no-repeat bottom left;
	overflow:hidden;
	white-space: nowrap;
	text-indent: 999em;
}


